import {
  AtomicBlockUtils,
  ContentState,
  EditorState,
  Modifier,
  convertToRaw
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import React, { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { toast } from "react-toastify";
import { api } from "../../customAxios";


function CustomTextEditor(props) {
  const {
    handleGetEditorState,
    description,
    taskId,
    isEditDescription,
    placeholder,
    isComment,
    isUploadTaskView,
    isUploadTempFile,
    isAddUpdate,
    projectUser,
    setProgress,
  } = props;
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  useEffect(() => {
    if (isEditDescription) {
      setEditorState(
        description
          ? EditorState.createWithContent(
            ContentState.createFromBlockArray(
              htmlToDraft(description).contentBlocks
            )
          )
          : EditorState?.createEmpty()
      );
    }
    if (isAddUpdate) {
      setEditorState(EditorState.createEmpty());
    }
  }, [isEditDescription, isAddUpdate, !isComment ? description : ""]);

  const onEditorStateChange = (currentEditorState) => {
    setEditorState(currentEditorState);
    if (handleGetEditorState) {
      handleGetEditorState(
        draftToHtml(convertToRaw(currentEditorState?.getCurrentContent()))
      );
    }
  };

  const handlePastedFiles = (files) => {
    const file = files[0];
    if (file.size > 2 * 1024 * 1024) {
      toast.error("File size exceeds the limit of 2 MB.");
      return;
    }
    if (setProgress) {
      setProgress(30);
    }
    if (isUploadTaskView) {
      const formData = new FormData();
      formData.append("model_id", taskId);
      formData.append("model_name", "Task");
      formData.append("file", files[0]);
      api
        .post(`/files`, formData)
        .then((action) => {
          if (action?.status === 200) {
            setEditorState(insertImage(action?.data?.name));
            props.handleGetFile(action?.data);
            if (setProgress) {
              setProgress(60);
            }
          }
        })
        .finally(() => {
          if (setProgress) {
            setProgress(100);
          }
        });
    } else if (isUploadTempFile) {
      const formData = new FormData();
      formData.append("file", files[0]);
      api
        .post("temp/files", formData)
        .then((response) => {
          setEditorState(insertImage(response?.data?.name));
          props.handleGetFile(response?.data);
          if (setProgress) {
            setProgress(60);
          }
        })
        .finally(() => {
          if (setProgress) {
            setProgress(100);
          }
        });
    }
  };

  const insertImage = (url) => {
    const contentState = editorState?.getCurrentContent();
    const widthPx = 200;
    const contentStateWithEntity = contentState?.createEntity(
      "IMAGE",
      "IMMUTABLE",
      {
        src: url,
        width: `${widthPx}px`,
        height: "auto",
        alignment: "left",
        border: "2px solid #ddd",
        borderRadius: "8px",
      }
    );
    const entityKey = contentStateWithEntity?.getLastCreatedEntityKey();
    const newEditorState = EditorState.set(editorState, {
      currentContent: contentStateWithEntity,
    });
    return AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, " ");
  };

  const mentionsSuggestion =
    projectUser?.length > 0 &&
    projectUser.map((user) => ({
      text: user.name,
      value: user?.name?.split(" ")[0],
    }));

  const mentionSuggestions =
    mentionsSuggestion &&
    mentionsSuggestion?.map((mention) => ({
      ...mention,
      component: (props) => (
        <div className="mention-suggestion">
          <img className="mention-suggestion-image" alt={mention.text} />
          <div className="mention-suggestion-text">{mention.text}</div>
        </div>
      ),
    }));
  const blockRenderer = (contentBlock) => {
    const type = contentBlock.getType();
    if (type === "atomic") {
      return {
        component: AtomicBlock,
        editable: false,
      };
    }
    return null;
  };
  const AtomicBlock = (props) => {
    const { block, contentState } = props;
    const entity = contentState.getEntity(block.getEntityAt(0));
    const type = entity.getType();
    if (type === "IMAGE") {
      const { src } = entity.getData();
      return <img src={src} alt="AtomicBlock" style={{ maxWidth: "50%" }} />;
    }
    return null;
  };

  const handlePastedText = (text, html) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    if (urlRegex.test(text)) {
      const contentState = editorState.getCurrentContent();
      const contentStateWithLink = contentState.createEntity(
        "LINK",
        "MUTABLE",
        { url: text }
      );
      const entityKey = contentStateWithLink.getLastCreatedEntityKey();

      const selectionState = editorState.getSelection();
      const contentStateWithText = Modifier.replaceText(
        contentState,
        selectionState,
        text, // Use the pasted text as the display text
        null,
        entityKey
      );

      const newEditorState = EditorState.push(
        editorState,
        contentStateWithText,
        "insert-characters"
      );

      setEditorState(newEditorState);
      return true; // Prevent default paste behavior
    }
    return false;
  };


  return (
    <Editor
      wrapperClassName="wrapper-class"
      editorClassName="editor-class"
      toolbarClassName="toolbar-class"
      id="jobDescriptionFocus"
      placeholder={placeholder ? placeholder : "Enter text here"}
      editorState={editorState}
      onEditorStateChange={onEditorStateChange}
      handlePastedFiles={handlePastedFiles}
      handlePastedText={handlePastedText}
      blockRendererFn={blockRenderer}
      className="p-10 text-black"
      toolbar={{
        options: [
          "inline",
          "blockType",
          "list",
          "textAlign",
          "link",
          "colorPicker",
          "emoji",
          "remove",
          "history",
        ],
        inline: {
          options: [
            "bold",
            "italic",
            "underline",
            "strikethrough",
            "monospace",
          ],
          bold: { className: "custom-classname" },
          italic: { className: "custom-classname" },
          underline: { className: "custom-classname" },
          strikethrough: { className: "custom-classname" },
          monospace: { className: "custom-classname" },
        },
        list: {
          options: ["unordered", "ordered", "indent", "outdent"],
        },
        blockType: {
          options: ["Normal", "H1", "H2", "H3", "H4", "H5", "H6", "Blockquote"],
        },
        textAlign: {
          options: ["left", "center", "right", "justify"],
        },
        link: {
          inDropdown: false,
        },
      }}
      mention={{
        separator: " ",
        trigger: "@",
        suggestions: mentionSuggestions,
      }}
    />
  );
}

export default CustomTextEditor;

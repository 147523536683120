import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useRef, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import { toast } from "react-toastify";
import {
  getFileName,
  getTimeAgo,
  handleDownloadDocument,
  htmlTagRegex,
  renderFileIcon,
  shortenFileName,
} from "../../../../Utils/helpers";
import {
  closeLightBox,
  launchLightBox,
  selectTempFiles,
} from "../../../../componets/comman/CommonFunction/TaskFunctions";
import CustomTextEditor from "../../../../componets/comman/CustomTextEditor";
import FileViewBox from "../../../../componets/comman/Projects/FileLightBox";
import CustomAvatar from "../../../../componets/comman/Task/CustomAvatar";
import CircularLoader from "../../../../componets/pageLoaders/projectLoaders/CircularLoader";
import ConfirmDialog from "../../../../componets/shared/ConfirmDialog";
import { api } from "../../../../customAxios";

function TaskComments(props) {
  const { taskDetail, projectUser, isFile, fileDetail, setProgress, isPublicPage } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [files, setFiles] = useState([]);
  const [commentId, setCommentId] = useState("");
  const [taskToUpdate, setTaskToUpdate] = useState(taskDetail);
  const [message, setMessage] = useState("");
  const [taskComments, setTaskComments] = useState(
    taskDetail?.comments || fileDetail?.comments
  );
  const [fileList, setFileList] = useState([]);
  const [loadingState, setLoadingState] = useState({
    loading: false,
    isEdit: false,
    isReply: false,
    wait: false,
    open: false,
    showAllComments: false,
    showMore: false,
  });
  const [openLightBox, setOpenLightBox] = useState(false);
  const [lightBoxImages, setLightBoxImages] = useState([]);
  const fileInputRef = useRef();
  const navigate = useNavigate();
  const location = useLocation();
  const storedUserData = localStorage.getItem("user");
  const JsonUser = JSON.parse(storedUserData);
  const loggedUserId = JsonUser?.id;
  const handleGetEditorState = (currentEditorState) => {
    setIsAddUpdate(false);
    setMessage(currentEditorState);
  };

  const handleFileChange = (event) => {
    selectTempFiles(event, setProgress, setFileList);
  };

  const handleGetFile = (file) => {
    setFileList((prevFileList) => [...prevFileList, file]);
  };

  const handleDeselect = (file) => {
    setProgress(30);
    const updatedFileList = fileList.filter((selectedFile) =>
      loadingState.isEdit && file.id
        ? selectedFile.id !== file.id
        : selectedFile !== file
    );
    const updatedFiles = Array.isArray(files)
      ? files.filter((fileInFiles) => fileInFiles.name !== file.name)
      : [];
    if (loadingState.isEdit && file.id) {
      api
        .delete(`files/${file.id}`)
        .then(() => {
          setFileList(updatedFileList);
          setProgress(60);
        })
        .catch(() => {
          toast.error("Oops! Something went wrong.");
        })
        .finally(() => {
          setProgress(100);
        });
    } else {
      setFiles(updatedFiles);
      setFileList(updatedFileList);
    }
  };

  const removeText = () => {
    setIsAddUpdate(true);
    setLoadingState((prevState) => ({
      ...prevState,
      isReply: false,
      isEdit: false,
      wait: false,
    }));
    setFileList([]);
    setFiles([]);
    setMessage("");
    setIsEditDescription(false);
  };

  const refreshComment = (commentId) => {
    const updatedComment = taskComments.filter(
      (comment) => comment.id !== commentId
    );
    setTaskComments(updatedComment);
    setTaskToUpdate((prevTask) => {
      const updatedTask = {
        ...prevTask,
        comments: updatedComment,
      };
      refreshPage(updatedTask);
      return updatedTask;
    });
  };
  const handleCommentData = () => {
    setIsLoading(true);
    if (
      !message.trim() &&
      (!fileList || (Array.isArray(fileList) && fileList.length === 0))
    ) {
      toast.error("Enter message or select file!");
      setIsLoading(false);
      return;
    }
    const cleanedMessage = message.replace(/<\/?[^>]+>|&[^;]+;/g, "");
    const lengthMessage = cleanedMessage.length - 1;
    if (lengthMessage > 5000) {
      toast.error("Exceeded maximum character length of 5000");
      return;
    }
    setLoadingState((prevState) => ({ ...prevState, wait: true }));
    const formData = new FormData();
    formData.append("message", message);
    formData.append(
      "model_id",
      loadingState.isEdit
        ? commentId
        : fileDetail
          ? fileDetail?.id
          : taskDetail?.task_id
    );
    formData.append(
      "model_name",
      loadingState.isEdit ? "Comment" : fileDetail ? "File" : "Task"
    );
    if (loadingState.isEdit) {
      const commentToUpdate = taskComments?.find(
        (comment) => comment.id === commentId
      );
      const newFiles = fileList?.filter(
        (file) =>
          !commentToUpdate?.files?.some(
            (commentFile) => commentFile.id === file.id
          )
      );
      if (Array.isArray(newFiles)) {
        newFiles.forEach((file, index) => {
          formData.append(`file_id[${index}]`, file.id);
        });
      } else {
        formData.append("file_id[0]", newFiles);
      }
    } else {
      if (Array.isArray(fileList)) {
        fileList.forEach((file, index) => {
          formData.append(`file_id[${index}]`, file?.id);
        });
      } else {
        formData.append("file_id[0]", fileList);
      }
    }

    const url = loadingState.isEdit ? `/comments/${commentId}` : `/comments`;
    api
      .post(url, formData)
      .then((resp) => {
        const newComment = resp.data.comment;
        if (loadingState.isEdit) {
          const updatedComments = taskComments.map((comment) =>
            comment.id === commentId ? newComment : comment
          );
          setTaskComments(updatedComments);
          setIsAddUpdate(true);
          setIsEditDescription(false);
          toast.success("Comment updated successfully!");
        } else {
          if (loadingState.isReply) {
            toast.success("Reply posted successfully!");
          } else {
            toast.success("Comment posted successfully!");
            setIsAddUpdate(true);
          }
          setTaskToUpdate((prevTask) => {
            const updatedTask = {
              ...prevTask,
              comments: [...prevTask.comments, newComment],
            };
            setTaskComments([...prevTask.comments, newComment]);
            refreshPage(updatedTask);
            return updatedTask;
          });
        }
      })
      .catch(() => {
        toast.error("Oops! something went wrong.");
        setIsLoading(false);
      })
      .finally(() => {
        removeText();
        setIsLoading(false);
      });
  };
  const refreshPage = (updatedData) => {
    const data = {
      taskId: updatedData.task_id,
      projectId: updatedData.project_id,
      updateTask: updatedData,
    };
    navigate(location.pathname, { state: { data } });
  };
  const handleModal = (deleteId, userId) => {
    if (loggedUserId === userId) {
      setCommentId(deleteId);
      setLoadingState((prevState) => ({ ...prevState, open: true }));
    } else {
      toast.warning("You can't delete this comment!");
    }
  };
  const handleDeleteComment = () => {
    setLoadingState((prevState) => ({
      ...prevState,
      open: false,
    }));
    setProgress(30);
    api
      .delete(`/comments/${commentId}`)
      .then(() => {
        setProgress(70);
        refreshComment(commentId, null);
        toast.success("Comment deleted successfully!");
      })
      .catch(() => {
        toast.error("Oops! something went wrong.");
      })
      .finally(() => {
        setProgress(100);
      });
  };
  const [isEditDescription, setIsEditDescription] = useState(false);
  const [isAddUpdate, setIsAddUpdate] = useState(false);

  const handleEditComment = (commentId, userId) => {
    setIsEditDescription(true);
    if (loggedUserId === userId) {
      const targetedComment = taskComments?.find(
        (comment) => comment.id === commentId
      );
      setLoadingState((prevState) => ({ ...prevState, isEdit: true }));
      setFileList(targetedComment.files);
      setMessage(targetedComment.message);
      setIsAddUpdate(false);
      setCommentId(commentId);
    } else {
      toast.warning("You can't edit this comment!");
    }
  };

  const downloadAttachDocument = async (file) => {
    handleDownloadDocument(file);
    toast.success("File downloaded successfully!");
  };
  const handleOpenLightBox = (file, commentFiles) => {
    launchLightBox(file, commentFiles, setOpenLightBox, setLightBoxImages);
  };
  const handleCloseLightBox = () => {
    closeLightBox(setLightBoxImages, setOpenLightBox);
  };
  const toggleShowAllComments = () => {
    setLoadingState((prevState) => ({
      ...prevState,
      showAllComments: !loadingState.showAllComments,
    }));
  };

  const handleImageClick = (file) => {
    const viewFile = {
      src: file,
      alt: file,
    };
    setLightBoxImages([viewFile]);
    setOpenLightBox(true);
  };

  const handleHTMLContentClick = (event) => {
    event.preventDefault();
    const target = event.target;
    if (target.tagName === "IMG") {
      handleImageClick(target.src);
    }
  };
  const renderCommentMessage = (comment, _isLengthText) => {
    const hasHTMLTags = htmlTagRegex(comment.message);
    if (hasHTMLTags) {
      return (
        <p
          className="ms-5 over_flow_wrap task_comment_message add-cursor-to-image capitalize-first"
          dangerouslySetInnerHTML={{
            __html: _isLengthText
              ? comment.message && comment.message.substr(0, 800) + "..."
              : comment.message && comment.message[8]
                ? comment.message
                : "",
          }}
          onClick={handleHTMLContentClick}
        />
      );
    } else {
      return (
        <p className="ms-5 over_flow_wrap mb-0 capitalize-first">
          {_isLengthText
            ? comment.message && comment.message.substr(0, 250) + "..."
            : comment.message}
        </p>
      );
    }
  };
  const taskCommentComponent = (comment) => {
    const hasHtml = htmlTagRegex(comment?.message);
    const limit = hasHtml ? 800 : 250;
    return (
      <>
        {comment?.message?.length <= limit || loadingState.showMore
          ? renderCommentMessage(comment)
          : renderCommentMessage(comment, true)}
        {comment?.message?.length > limit && (
          <span
            tabIndex={0}
            className="ms-5 link-color cursor-hover text-decoration-none d-block f-14 font-weight-600"
            onClick={() =>
              setLoadingState((prevState) => ({
                ...prevState,
                showMore: !prevState.showMore,
              }))
            }
          >
            {loadingState.showMore ? "Show less" : "Show more"}
          </span>
        )}
      </>
    );
  };
  const displayedComments =
    taskComments && loadingState.showAllComments
      ? taskComments?.slice().reverse()
      : taskComments?.slice().reverse().slice(0, 5);

  return (
    <>
      {loadingState.loading && <CircularLoader />}

      {taskComments &&
        displayedComments?.map((comment) => (
          <>
            <div className="comment mt-3" key={comment.id}>
              <div class="d-flex justify-content-between">
                <div class="user-info d-flex float-start ">
                  {comment.created_by_image ? (
                    <div
                      className="size_cover user_comment"
                      style={{
                        backgroundImage: `url('${comment.created_by_image}')`,
                      }}
                    ></div>
                  ) : (
                    <CustomAvatar
                      name={comment.created_by_name || ""}
                      backgroundColor={comment.profile_color}
                      height={36}
                      width={36}
                      fontSize={16}
                    />
                  )}
                  <strong className="me-2 user_name text-capitalize">
                    {comment.created_by_name}
                  </strong>
                  <strong className="time_ago">
                    {getTimeAgo(comment.created_at)}
                  </strong>
                </div>
                <div className="action">
                  <Dropdown>
                    <div
                      className={`option-icon ${taskDetail?.userPermission?.can_i_edit === false &&
                        "bg-unset"
                        }`}
                    >
                      <Dropdown.Toggle
                        id="dropdown-basic"
                        className={`button_style ${taskDetail?.userPermission?.can_i_edit === false &&
                          "not-allowed-disable sprint-action"
                          }`}
                        disabled={
                          loadingState.isEdit && commentId === comment.id
                        }
                      >
                        <FontAwesomeIcon icon={faEllipsisV} />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={() =>
                            handleEditComment(comment.id, comment.created_by_id)
                          }
                        >
                          Edit
                        </Dropdown.Item>
                        {/* <Dropdown.Item
                                                      onClick={() => handleReplyComment(comment.id)}
                                                  >
                                                      Reply
                                                  </Dropdown.Item> */}
                        {taskDetail.userPermission?.can_i_delete && (
                          <Dropdown.Item
                            onClick={() =>
                              handleModal(comment.id, comment.created_by_id)
                            }
                          >
                            Delete
                          </Dropdown.Item>
                        )}
                      </Dropdown.Menu>
                    </div>
                  </Dropdown>
                </div>
              </div>
              {taskCommentComponent(comment)}
              <div className="file_row ms-5 mt-3">
                {comment?.files?.map((file, index) => (
                  <div className="file_divs viewtask-files mb-3">
                    <div className="file_main_section" key={index + 1}>
                      <div
                        className="mic-vid-icon add_cursor"
                        style={{ marginTop: "10px !important" }}
                      >
                        <div
                          className="imgs p-2 h-100"
                          onClick={() =>
                            handleOpenLightBox(file, comment.files)
                          }
                        >
                          {renderFileIcon(file)}
                        </div>
                      </div>

                      <div className="file_main_section_left">
                        <p className="m-b-10 add_cursor">
                          {shortenFileName(getFileName(file?.name))}
                        </p>
                        <div className="d-flex justify-content-between mt-4px">
                          <div className="d-flex">
                            <p className="time mt-2">
                              {" "}
                              {getTimeAgo(file.created_at)}{" "}
                            </p>
                          </div>
                          <Dropdown>
                            <div className="option-icon">
                              <Dropdown.Toggle
                                id="dropdown-basic"
                                className="button_style"
                              >
                                <FontAwesomeIcon icon={faEllipsisV} />
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Item
                                  onClick={() => downloadAttachDocument(file)}
                                >
                                  Download
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </div>
                          </Dropdown>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </>
        ))}
      {taskComments && taskComments?.length > 5 && (
        <div className="text-center show_more_comment mt-3">
          <span
            className={`horizontal-line ${loadingState.showAllComments ? "w-34" : "w-32"
              }`}
          ></span>
          <span className="show_more" onClick={toggleShowAllComments}>
            Show {!loadingState.showAllComments && taskComments.length - 5}{" "}
            {loadingState.showAllComments ? "less" : "more"} comments{" "}
          </span>
          <span
            className={`horizontal-line ${loadingState.showAllComments ? "w-34" : "w-32"
              }`}
          ></span>
        </div>
      )}
      {(taskDetail?.userPermission?.can_i_edit || isFile) && (
        <>
          <h5 className="text-secondary mt-3">Post a comment</h5>
          <div
            className={`edit__wiki__box_2 ${files.length > 0 ? "comment_editor" : ""
              } mb-4 `}
          >
            <CustomTextEditor
              handleGetEditorState={handleGetEditorState}
              isComment={true}
              description={message}
              isEditDescription={isEditDescription}
              isAddUpdate={isAddUpdate}
              placeholder={
                "Write your comment and type @ to mention someone..."
              }
              projectUser={projectUser}
              isUploadTempFile={true}
              handleGetFile={handleGetFile}
              setProgress={setProgress}
            />
          </div>

          <div className="d-flex justify-content-between ">
            <button className="btn btn-cancel btn-sm" onClick={removeText}>
              Cancel
            </button>
            <div className="d-flex align-items-center ">
              <div className="attachment">
                <label
                  htmlFor="fileInput1"
                  className="btn-label btn btn-default d-flex align-items-center"
                >
                  + Add Files
                  <input
                    type="file"
                    id="fileInput1"
                    multiple
                    name="file"
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                    ref={fileInputRef}
                  />
                </label>
              </div>

              <button
                className={`btn btn-primary btn-sm ms-4 ${isLoading ? "pd-post-commt-load" : ""
                  }`}
                disabled={loadingState.wait || isLoading}
                onClick={handleCommentData}
              >
                {isLoading ? (
                  <ClipLoader color="#ffffff" loading={isLoading} size={20} />
                ) : loadingState.isEdit ? (
                  "Update"
                ) : loadingState.isReply ? (
                  "Reply"
                ) : (
                  "Post"
                )}
              </button>
            </div>
          </div>
        </>
      )}

      <div className="file_row mt-3">
        {fileList.map((file, index) => (
          <div className="file_divs viewtask-files mb-3">
            <div className="file_main_section" key={index + 1}>
              <div
                className="mic-vid-icon add_cursor"
                style={{ marginTop: "10px !important" }}
                onClick={() => handleOpenLightBox(file, fileList)}
              >
                <div className="imgs p-2 h-100">{renderFileIcon(file)}</div>
              </div>

              <div className="file_main_section_left">
                <p className="m-b-10 add_cursor">
                  {shortenFileName(getFileName(file?.name))}
                </p>
                <div className="d-flex justify-content-between mt-4px">
                  <div className="d-flex">
                    <p className="time mt-2">
                      {" "}
                      {getTimeAgo(file?.created_at)}{" "}
                    </p>
                  </div>
                  <Dropdown>
                    <div className="option-icon">
                      <Dropdown.Toggle
                        id="dropdown-basic"
                        className="button_style"
                      >
                        <FontAwesomeIcon icon={faEllipsisV} />
                      </Dropdown.Toggle>
                      {taskDetail.userPermission?.can_i_delete && (
                        <Dropdown.Menu>
                          <Dropdown.Item onClick={() => handleDeselect(file)}>
                            Delete
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      )}
                    </div>
                  </Dropdown>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <ConfirmDialog
        open={loadingState.open}
        setOpen={setLoadingState}
        dialogTitle="Are you sure?"
        handleConfirmYes={handleDeleteComment}
      />
      <FileViewBox
        images={lightBoxImages}
        isOpen={openLightBox}
        onClose={handleCloseLightBox}
      />
    </>
  );
}
export default TaskComments;
